@keyframes animate-loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#loader::before {
  content: '';
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 4px solid #1c3cd8;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  animation: animate-loader 1s linear infinite;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: uberMove-regular;
  src: url("../fonts/Uber_Move-Regular.woff2");
  font-display: swap;
}

@font-face {
  font-family: uberMove-medium;
  src: url("../fonts/Uber_Move-Medium.woff2");
  font-display: swap;
}

@font-face {
  font-family: uberMove-bold;
  src: url("../fonts/Uber_Move-Bold.woff2");
  font-display: swap;
  font-weight: bold;
}

.regular {
  font-family: uberMove-regular, sans-serif;
}

.medium {
  font-family: uberMove-medium, sans-serif;
}

.bold {
  font-family: uberMove-bold, sans-serif;
}

:root {
  font-family: uberMove-regular, sans-serif;
  overflow-x: hidden;
}

button {
  outline: none;
  border: none;
}

button:active, button:focus {
  border: none;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.slide-enter,
.slide-exit,
.slide-appear {
  transition: all 800ms linear;
}

.slide-enter,
.slide-appear {
  opacity: 0;
}

.slide-enter-active,
.slide-appear-active {
  opacity: 1;
}

.slide-exit {
  opacity: 1;
}

.slide-exit-active {
  opacity: 0;
}

body {
  background-color: #faf9fe;
}

img {
  width: 100%;
}

.social-icons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.social-icons img {
  width: 22px;
}

.grid-container {
  display: grid;
  gap: 1rem;
}

.home {
  margin: 2rem 4rem;
}

@media (max-width: 800px) {
  .home {
    margin: 2rem 1rem;
  }
}

@media (max-width: 600px) {
  .home {
    margin: 1rem;
  }
}

.btn-link {
  text-decoration: none;
  color: #1c3cd8;
  font-size: 1.2rem;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid transparent;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: max-content;
  transition: all 0.3s;
}

.btn-link:hover {
  border: 1px solid #0430b4;
  color: #0430b4;
}

.btn-link.active:hover {
  border-radius: 5px;
  color: #ffff;
  background-color: #0430b4;
}

.active {
  background-color: #1c3cd8;
  color: #ffff;
}

.tagline {
  font-size: 1.2rem;
  line-height: 1.4;
}

@media (max-width: 800px) {
  .tagline {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .tagline {
    font-size: 0.9rem;
  }
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.2rem;
  margin: 5px;
}

@media (max-width: 800px) {
  h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 600px) {
  h2 {
    font-size: 1.6rem;
    margin: 10px;
  }
}

h3 {
  font-size: 1.6rem;
}

@media (max-width: 800px) {
  h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 600px) {
  h3 {
    font-size: 1.2rem;
  }
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  gap: 5rem;
  padding: 2rem 3rem;
  margin-top: 1.5rem;
  justify-items: center;
  align-items: center;
}

@media (max-width: 600px) {
  .card-container {
    padding: 2rem 1rem;
  }
}

.card-container .card {
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 1px 8px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
}

#copyright {
  text-align: center;
}

@media (max-width: 800px) {
  #copyright {
    margin-bottom: 1rem;
  }
}

.thankyou-message {
  margin: 6rem auto 2rem;
  text-align: center;
}

@media (max-width: 600px) {
  .thankyou-message {
    margin: 8rem auto 2rem;
  }
  .thankyou-message h1 {
    font-size: 2.3rem;
    margin-bottom: 10px;
    line-height: 1.1;
  }
  .thankyou-message p {
    font-size: 1rem;
  }
}

.thankyou .back-btn {
  margin: auto;
}

.thankyou #copyright {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
}

@media (max-width: 800px) {
  .thankyou #copyright {
    bottom: 5rem;
  }
}

.navbar {
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 0 2rem;
}

.navbar .nav-brand {
  width: 12rem;
  background: transparent;
  display: flex;
}

.navbar .nav-brand img {
  width: 100%;
}

.navbar .nav-btn-wrapper {
  display: none;
}

.navbar .nav-items-wrapper {
  display: block;
}

.navbar .nav-items {
  display: flex;
  flex-wrap: wrap;
  justify-self: flex-end;
}

.navbar .nav-items > * {
  align-self: flex-start;
}

.navbar .nav-items > *:not(:last-child) {
  margin-right: 1rem;
}

.navbar .nav-items #services-nav,
.navbar .nav-items #contact-nav {
  justify-items: center;
  position: relative;
  display: grid;
  grid-template-columns: 1fr min-content;
  cursor: pointer;
}

.navbar .nav-items #services-nav svg,
.navbar .nav-items #contact-nav svg {
  transform: translateX(5px) rotate(0deg);
  transition: all 0.3s;
}

.navbar .dropdown {
  width: max-content;
  background-color: #fff;
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  border: none;
  border-radius: 10px;
  padding: 10px 5px;
  box-shadow: inset -3px -2px 10px rgba(171, 169, 169, 0.3), -6px -9px 14px 3px rgba(217, 213, 213, 0.3);
}

.navbar .dropdown > * {
  width: 100%;
  border: none;
  position: relative;
}

.navbar .dropdown > *::after {
  content: '';
  position: absolute;
  top: 100%;
  width: 0;
  height: 1px;
  background: linear-gradient(to right, rgba(28, 60, 216, 0.7), rgba(28, 60, 216, 0.4), rgba(28, 60, 216, 0.7));
  transition: width 0.3s;
}

.navbar .dropdown > *:hover {
  border: none;
}

.navbar .dropdown > *:hover::after {
  width: 100%;
}

.navbar .dropdown .active:hover::after {
  width: 0;
}

.navbar .dropdown-wrapper {
  position: absolute;
  top: 2rem;
  left: 0;
  transform: translateX(-35%);
  z-index: 10;
  display: none;
  grid-column: 1/-1;
  padding: 0;
  background-color: #fff;
}

.navbar .dropdown-opened {
  display: grid;
}

.navbar .dropdown-opened svg {
  transform: translateX(5px) rotate(180deg) !important;
}

.navbar #services-nav .dropdown-wrapper,
.navbar #contact-nav .dropdown-wrapper {
  background: transparent;
  padding: 1rem 0;
}

.navbar #services-nav:hover .dropdown-wrapper,
.navbar #contact-nav:hover .dropdown-wrapper {
  display: block;
}

.navbar #services-nav:hover svg,
.navbar #contact-nav:hover svg {
  transform: translateX(5px) rotate(180deg);
}

.navbar #contact-nav .dropdown-wrapper {
  transform: translateX(-10%);
}

@media (max-width: 800px) {
  .navbar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: end;
    z-index: 10;
    background: transparent;
  }
  .navbar .nav-brand {
    justify-self: start;
    width: 10rem;
    padding: 10px 0;
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
  }
  .navbar .nav-btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100%;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.325);
    padding: 12px 1rem;
    z-index: 13;
  }
  .navbar .nav-btn-wrapper .nav-btn {
    border: 1px solid rgba(0, 0, 0, 0.325);
    border-radius: 5px;
    padding: 0.6rem 0.5rem;
    padding-left: 1rem;
    cursor: pointer;
    background: transparent;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }
  .navbar .nav-btn-wrapper .nav-btn .hamburger-menu {
    background-color: #000;
    width: 28px;
    height: 2px;
    transition: all 0.5s;
  }
  .navbar .nav-btn-wrapper .nav-btn .hamburger-menu:nth-of-type(1) {
    transform: translateX(0);
  }
  .navbar .nav-btn-wrapper .nav-btn .hamburger-menu:nth-of-type(2) {
    transform: translateX(-10px);
    margin: 7px auto;
  }
  .navbar .nav-btn-wrapper .nav-btn .hamburger-menu:nth-of-type(3) {
    transform: translateX(0);
  }
  .navbar .nav-items-wrapper {
    grid-column: 1/-1;
    justify-self: center;
    display: block;
    padding: 1rem 0;
    border: none;
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 12;
    transform: translateX(-100%);
    width: 100vw;
    left: 0;
    border-radius: 0;
    background: transparent;
    backdrop-filter: blur(47px);
    transition: all 0.5s;
  }
  .navbar .nav-items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .navbar .nav-items > * {
    align-self: center;
  }
  .navbar .nav-items > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .navbar .nav-items > *:nth-child(3) span,
  .navbar .nav-items > *:nth-child(5) span {
    transform: translateX(10%);
  }
  .navbar .nav-items .btn-link {
    background-color: #fff;
    width: 40%;
  }
  .navbar .nav-items .active {
    background-color: #1c3cd8;
  }
  .navbar .dropdown-wrapper {
    width: 100%;
    position: static;
    transform: none;
    border: none;
    padding: 0.5rem 0;
  }
  .navbar .dropdown {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    text-align: center;
    width: 100%;
    padding: 5px 0;
    box-shadow: none;
    border-radius: 0;
  }
  .navbar .dropdown .btn-link {
    width: 100% !important;
  }
  .navbar #services-nav .dropdown-wrapper,
  .navbar #contact-nav .dropdown-wrapper {
    padding: 10px 0 5px;
    transform: none;
  }
  .navbar #services-nav:hover .dropdown-wrapper,
  .navbar #contact-nav:hover .dropdown-wrapper {
    display: none;
  }
  .navbar #services-nav:hover svg,
  .navbar #contact-nav:hover svg {
    transform: translateX(5px) rotate(0deg);
  }
}

@media (max-width: 600px) {
  .navbar {
    padding: 0px 10px;
  }
  .navbar .nav-brand {
    padding: 10px 0;
    width: 8rem;
  }
  .navbar .nav-items .btn-link {
    width: 65%;
  }
}

@media (max-width: 400px) {
  .navbar .nav-btn-wrapper .nav-btn .hamburger-menu {
    width: 20px;
    height: 2px;
  }
  .navbar .nav-btn-wrapper .nav-btn .hamburger-menu:nth-of-type(1) {
    transform: translateX(0);
  }
  .navbar .nav-btn-wrapper .nav-btn .hamburger-menu:nth-of-type(2) {
    width: 22px;
    transform: translateX(-8px);
    margin: 5px auto;
  }
  .navbar .nav-btn-wrapper .nav-btn .hamburger-menu:nth-of-type(3) {
    transform: translateX(0);
  }
  .navbar .nav-items .btn-link {
    width: 75%;
  }
}

.navbar-opened .nav-btn-wrapper .nav-btn .hamburger-menu:nth-of-type(1) {
  transform: translateX(-6px);
}

@media (max-width: 400px) {
  .navbar-opened .nav-btn-wrapper .nav-btn .hamburger-menu:nth-of-type(1) {
    transform: translateX(-4px);
  }
}

.navbar-opened .nav-btn-wrapper .nav-btn .hamburger-menu:nth-of-type(2) {
  transform: translateX(4px);
}

@media (max-width: 400px) {
  .navbar-opened .nav-btn-wrapper .nav-btn .hamburger-menu:nth-of-type(2) {
    transform: translateX(3px);
  }
}

.navbar-opened .nav-btn-wrapper .nav-btn .hamburger-menu:nth-of-type(3) {
  transform: translateX(-6px);
}

@media (max-width: 400px) {
  .navbar-opened .nav-btn-wrapper .nav-btn .hamburger-menu:nth-of-type(3) {
    transform: translateX(-4px);
  }
}

.navbar-opened .nav-items-wrapper {
  transform: translateX(0);
}

.icon {
  position: absolute;
  z-index: -1;
}

.rectangle {
  right: -25px;
}

.golden-triangle {
  width: 50px;
  margin: auto;
}

.background-shape {
  display: flex;
  justify-content: flex-end;
}

.background-shape img {
  width: auto;
}

.ellipse7 {
  width: 120px;
  left: 20%;
  transform: translateY(-70px);
}

.ellipse8 {
  width: 80px;
  right: 20%;
  transform: translateY(10px);
}

.green-triangle {
  right: 25%;
  transform: translateY(-10px);
}

.square {
  width: 140px;
  left: 10%;
}

@media (max-width: 800px) {
  .rectangle {
    width: 50px;
  }
  .golden-triangle {
    width: 30px;
  }
  .background-shape {
    height: 40px;
  }
  .ellipse7 {
    width: 80px;
  }
  .ellipse8 {
    width: 50px;
  }
  .green-triangle {
    width: 30px;
  }
  .square {
    width: 70px;
  }
}

.defaultUI,
.customUI {
  grid-template-columns: repeat(7, 1fr);
  margin: 6rem 0;
  margin-bottom: 3rem;
  padding: 0 2rem;
}

.defaultUI .content,
.customUI .content {
  grid-column: span 4;
  display: grid;
  gap: 3rem;
  align-items: center;
}

.defaultUI .content h1,
.customUI .content h1 {
  width: 100%;
}

.defaultUI .content .btn-link,
.customUI .content .btn-link {
  padding: 10px 15px;
}

.defaultUI .image-box,
.customUI .image-box {
  grid-column: span 3;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

@media (min-width: 1400px) {
  .defaultUI .content,
  .customUI .content {
    gap: 1.5rem;
  }
}

@media (max-width: 1000px) {
  .defaultUI .content,
  .customUI .content {
    gap: 2rem;
  }
  .defaultUI .content h1,
  .customUI .content h1 {
    font-size: 2.5rem;
  }
  .defaultUI .content .tagline,
  .customUI .content .tagline {
    font-size: 1.1rem;
    width: 95%;
  }
}

@media (max-width: 800px) {
  .defaultUI,
  .customUI {
    grid-template-columns: 1fr;
    gap: 3rem;
    align-items: center;
    justify-items: center;
    padding: 0 1rem;
    margin: 8rem 0;
  }
  .defaultUI .content,
  .customUI .content {
    grid-column: 1/-1;
    justify-items: center;
    text-align: center;
  }
  .defaultUI .content .btn-link,
  .customUI .content .btn-link {
    justify-self: center;
  }
  .defaultUI .content h1,
  .customUI .content h1 {
    font-size: 2.2rem;
  }
  .defaultUI .content .tagline,
  .customUI .content .tagline {
    font-size: 1rem;
    width: 100%;
  }
  .defaultUI .image-box,
  .customUI .image-box {
    grid-column: 1/-1;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .defaultUI .content,
  .customUI .content {
    gap: 1.5rem;
  }
  .defaultUI .content .tagline,
  .customUI .content .tagline {
    font-size: 0.9rem;
  }
}

.defaultUI .image-box img {
  width: 90%;
}

@media (max-width: 1000px) {
  .defaultUI .image-box img {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .defaultUI .image-box img {
    width: 80%;
  }
}

.customUI .image-box img {
  width: 70%;
  transform: translateX(-10%);
}

@media (max-width: 1200px) {
  .customUI .image-box img {
    width: 90%;
  }
}

@media (max-width: 800px) {
  .customUI .image-box img {
    width: 85%;
    transform: translateX(0);
  }
}

@media (max-width: 600px) {
  .defaultUI .content h1 {
    font-size: 2rem;
  }
  .customUI .content h1 {
    font-size: 1.8rem;
  }
}

.business-essentials {
  text-align: center;
  padding: 1rem 3rem;
}

@media (max-width: 600px) {
  .business-essentials {
    padding: 1rem;
  }
}

.business-essentials .tagline {
  line-height: 1.3;
}

.card-wrapper {
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-rows: 1fr;
  align-self: stretch;
}

.card-wrapper .card {
  display: grid;
  gap: 2rem;
  align-content: center;
  padding: 1.5rem 2rem;
}

.card-wrapper .card img {
  width: 100%;
}

.card-wrapper .btn-link {
  padding: 8px 12px;
  border-radius: 6px;
}

.planning {
  text-align: center;
  padding: 1rem 3rem;
  margin-top: 6rem;
}

.planning > h2 {
  margin-bottom: 1rem;
}

.planning > .tagline {
  width: 70%;
  margin: auto;
}

@media (max-width: 600px) {
  .planning > .tagline {
    width: 100%;
  }
}

.planning .service-card-container {
  margin: 2rem 0;
}

.planning .service-card-container .card-wrapper {
  border-radius: 10px;
}

.planning .service-card-container .card-wrapper .card {
  padding: 0;
  border-radius: 10px;
}

.planning .service-card-container .card-wrapper .card img {
  border-radius: 10px;
  display: block;
  width: 100%;
}

.planning .service-card-container .card-wrapper .tagline {
  font-size: 1.3rem;
}

.planning .card-wrapper .card {
  justify-items: center;
  padding-bottom: 2rem;
  background-color: #f0effeab;
}

.planning .card-wrapper .card .textBox {
  padding: 1rem 0 0;
}

.planning .card-wrapper .card img {
  width: 80%;
}

@media (max-width: 600px) {
  .planning .card-wrapper .card img {
    width: 90%;
  }
}

.planning .btn-link {
  margin: auto;
  padding: 8px 12px;
  border-radius: 6px;
}

.planning .service-detail {
  width: 70%;
  border: 3.5px dashed #1c3cd8;
  border-radius: 12px;
  padding: 4rem;
  margin: 6rem auto 0;
}

.planning .goldenSquare {
  width: 50px;
  left: 8%;
}

.planning .rectangle {
  width: 100px;
  right: 6rem;
}

.planning .square {
  left: auto;
  right: 10%;
}

.planning .vertical-rectangle {
  width: 60px;
  left: 8%;
  transform: translateY(-50px);
}

.planning .ellipse7 {
  left: auto;
  right: 10%;
  width: 70px;
}

@media (max-width: 800px) {
  .planning .service-detail {
    width: 80%;
    padding: 4rem;
  }
  .planning .goldenSquare {
    width: 40px;
  }
  .planning .rectangle {
    width: 80px;
    right: 3rem;
  }
  .planning .square {
    transform: translateY(20px);
  }
  .planning .vertical-rectangle {
    width: 40px;
  }
  .planning .ellipse7 {
    width: 60px;
  }
}

@media (max-width: 600px) {
  .planning {
    padding: 1rem 2rem;
  }
  .planning .service-detail {
    width: 100%;
    padding: 2rem;
  }
  .planning .goldenSquare {
    width: 30px;
  }
  .planning .rectangle {
    width: 70px;
    right: 2rem;
  }
  .planning .vertical-rectangle {
    width: 35px;
  }
  .planning .ellipse7 {
    width: 50px;
  }
}

.procedure {
  margin: 6rem 0;
}

.procedure .card-container {
  gap: 8rem;
}

.procedure .card-container .round-arrow {
  display: none;
}

@media (min-width: 1130px) {
  .procedure .card-container {
    grid-template-columns: 1fr 140px 1fr 140px 1fr;
    gap: 0;
  }
  .procedure .card-container .round-arrow {
    display: block;
    transform: translateY(-75%);
    margin-right: 5px;
  }
  .procedure .card-container .round-arrow img {
    width: 100%;
  }
}

.procedure .card-container .card-wrapper .card {
  padding: 2rem 1.5rem;
  background-color: #1c3cd8;
}

.procedure h2 {
  margin: 2rem 0;
}

.procedure .green-triangle {
  align-self: flex-start;
  right: 10%;
  transform: translateY(-10px);
  width: 35px;
}

@media (max-width: 800px) {
  .procedure .green-triangle {
    right: 15%;
  }
}

@media (max-width: 600px) {
  .procedure .green-triangle {
    right: 8%;
    width: 30px;
    transform: translateY(-5px);
  }
}

.procedure .ellipse8 {
  right: auto;
  left: 10%;
  transform: translateY(-10px);
}

.testimonials {
  text-align: center;
  margin: 8rem 6rem;
}

.testimonials .testimonial-card {
  background-color: #f0effeab;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  gap: 2rem;
  margin: 3rem 0 4rem;
  padding: 0rem 3rem;
  align-items: center;
  justify-items: center;
}

.testimonials .testimonial-card .image-box {
  padding: 2rem;
}

.testimonials .testimonial-card .image-box img {
  width: 100%;
}

.testimonials .testimonial-card .text-box {
  padding: 2rem;
}

.testimonials .testimonial-card .text-box p:nth-child(1) {
  font-size: 3rem;
}

@media (max-width: 800px) {
  .testimonials {
    margin: 6rem 4rem;
  }
  .testimonials .testimonial-card {
    text-align: center;
  }
  .testimonials .testimonial-card .text-box {
    padding: 1rem 0;
  }
  .testimonials .testimonial-card .text-box p:nth-child(1) {
    font-size: 2.5rem;
  }
}

@media (max-width: 600px) {
  .testimonials {
    margin: 6rem 2rem;
  }
  .testimonials .testimonial-card {
    padding: 2rem;
  }
  .testimonials .testimonial-card .text-box p:nth-child(1) {
    font-size: 2rem;
  }
}

.text-representation-card {
  padding: 3rem;
  color: #1c3cd8;
  text-align: left;
}

@media (max-width: 1200px) {
  .text-representation-card {
    padding: 2rem;
  }
}

.portfolio {
  padding: 0 2rem;
}

.portfolio .portfolio-container {
  margin: 3rem 0;
  padding: 2rem;
}

.portfolio .portfolio-container > h2 {
  margin: 0;
}

.portfolio .portfolio-container .card-container {
  padding: 2rem 0;
}

.portfolio .portfolio-container .card-container .card-wrapper {
  border-radius: 6px;
}

.portfolio .portfolio-container .card-container .card-wrapper .card {
  border-radius: 6px;
  padding: 0;
}

.portfolio .portfolio-container .card-container .card-wrapper .card img {
  border-radius: 6px;
  display: block;
}

.portfolio .portfolio-container .btn-link {
  margin: 1rem auto;
}

@media (max-width: 800px) {
  .portfolio {
    padding: 0 1rem;
  }
  .portfolio .portfolio-container {
    margin: 8rem 0 3rem;
    padding: 0;
  }
}

#digital-showroom-iframe {
  height: 90%;
  width: 90%;
}

@media (max-width: 600px) {
  #digital-showroom-iframe {
    height: 80%;
  }
}

.popup__box {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 800px) {
  .popup__box {
    padding: 0rem;
  }
}

.popup__box-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
}

@media (max-width: 800px) {
  .popup__box-content {
    height: 50%;
  }
}

.popup__box-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.popup-btn {
  outline: 0;
  border: 0;
  cursor: pointer;
}

.close-icon {
  position: fixed;
  right: 0;
  top: 0;
  cursor: pointer;
  height: 50px;
  width: 50px;
  font-size: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ededed;
  margin: 1rem;
  color: #1a1a1a;
  transition: all 0.3s;
}

.close-icon:hover {
  color: #fff;
  background: red;
}

@media (max-width: 800px) {
  .close-icon {
    height: 40px;
    width: 40px;
    font-size: 2rem;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: 11;
  transform: scale(0.1);
  background: rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(20px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: scale(1);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: scale(0.1);
}

.form-section {
  background: linear-gradient(117deg, #1c3cd8, #00188d);
  color: #fff;
  margin: 8rem 0 0;
  padding: 6rem;
  padding-bottom: 3rem;
}

.form-section .rectangle {
  z-index: 1;
  width: 70px;
}

.form-section .right-rectangle {
  right: 10%;
}

.form-section .left-rectangle {
  left: 5%;
  transform: translateY(-80%);
}

.form-section h2 {
  text-align: center;
}

.form-section .grid-container {
  grid-template-columns: repeat(2, 1fr);
  margin: 5rem;
  align-items: center;
  gap: 2rem;
}

.form-section .grid-container form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 4rem;
  border-radius: 10px;
  background: #1a37c5;
}

.form-section .grid-container form .form-item {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}

.form-section .grid-container form .form-item label {
  letter-spacing: 1px;
}

.form-section .grid-container form .form-item > input,
.form-section .grid-container form .form-item > textarea {
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 10px 5px;
  background: linear-gradient(to left, #2742c8, #3e5ae6);
  color: #fff;
  transition: all 0.5s;
}

.form-section .grid-container form .form-item > input:focus,
.form-section .grid-container form .form-item > textarea:focus {
  outline: none;
  border: none;
  background: #fff;
  color: #1c3cd8;
}

.form-section .grid-container form button {
  background: #2143ad;
  border: none;
  outline: none;
  justify-self: start;
  padding: 10px 1.2rem;
  border-radius: 10px;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.5s;
}

.form-section .grid-container form button:hover {
  background: #133499;
}

.form-section .grid-container .info {
  padding: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.form-section .grid-container .info h2 {
  text-align: left;
  line-height: 1.3;
  margin: 0;
}

.form-section .grid-container .info .call-details p {
  margin-bottom: 2px;
  width: max-content;
}

.form-section .grid-container .info .call-details p a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.form-section .grid-container .info .call-details p a span {
  border-bottom: 1px solid #fff;
}

.form-section .grid-container .info .call-details p a svg {
  margin-left: 8px;
  width: 1.3rem;
  transform: translateY(2px);
}

@media (max-width: 1400px) {
  .form-section {
    padding: 4rem;
  }
  .form-section .grid-container {
    margin: 5rem 3rem;
  }
}

@media (max-width: 1100px) {
  .form-section {
    padding: 4rem 2rem;
  }
  .form-section .grid-container {
    margin: 4rem 1rem;
    gap: 1rem;
  }
  .form-section .grid-container .info h2 {
    font-size: 2rem;
  }
}

@media (max-width: 900px) {
  .form-section .grid-container {
    grid-template-columns: 1fr;
  }
  .form-section .grid-container .info {
    grid-row: 1/2;
  }
  .form-section .grid-container .info h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 600px) {
  .form-section {
    margin: 0;
    padding: 4rem 1rem;
  }
  .form-section .rectangle {
    width: 40px;
  }
  .form-section .grid-container form {
    padding: 2rem;
  }
  .form-section .grid-container .info {
    padding: 1rem;
  }
  .form-section .grid-container .info h2 {
    font-size: 1.6rem;
  }
  .form-section .grid-container .info .call-details p a svg {
    margin-left: 6px;
    width: 1rem;
  }
}

.about-us {
  margin: 2rem 4rem;
}

.about-us p {
  font-size: 1.3rem;
  line-height: 1.4;
}

.about-us h2 {
  font-size: 2.4rem;
  margin: 0;
}

.about-us .grid-container {
  grid-template-columns: 1fr 0.35fr;
  margin: 6rem auto;
  width: 90%;
}

.about-us .grid-container .content {
  padding: 2rem;
}

.about-us .grid-container .content h2 {
  margin-bottom: 3rem;
}

.about-us .grid-container .content p {
  width: 85%;
}

.about-us .grid-container .image-box {
  transform: translateX(-2rem);
}

.about-us .grid-container-2 {
  grid-template-columns: 0.35fr 1fr;
}

.about-us .grid-container-2 .image-box {
  grid-area: 1/1/2/2;
  transform: translateX(4rem);
  align-self: center;
}

.about-us .grid-container-2 .content {
  text-align: right;
}

.about-us .grid-container-2 .content p {
  margin-left: auto;
}

.about-us .core-values {
  text-align: center;
  display: grid;
  gap: 1.5rem;
  margin: 4rem 0;
  margin-top: 8rem;
}

.about-us .core-values p {
  width: 80%;
  margin: auto;
}

.about-us .grow-business {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  padding: 5rem;
  align-items: center;
  gap: 2rem;
  width: 90%;
  margin: auto;
}

.about-us .grow-business .content {
  display: grid;
  gap: 2rem;
}

.about-us .grow-business .content .text-content h2 {
  margin-bottom: 5px;
}

.about-us .grow-business .content a {
  padding: 8px 12px;
}

.about-us .grow-business .image-box {
  width: 80%;
}

.about-us .dark-rectangle {
  right: 7%;
  width: 45px;
  transform: translateY(20px);
}

.about-us .square-1 {
  transform: translateY(-8rem);
  width: 100px;
  left: 5%;
}

.about-us .white-rectangle {
  right: auto;
  left: 40%;
  width: 80px;
  transform: translateY(-6rem);
}

.about-us .green-bordered-square {
  left: 30%;
  transform: translateY(-4rem);
  width: 40px;
}

.about-us .ellipse8 {
  right: auto;
  left: 7%;
}

.about-us .square-2 {
  right: 5%;
  width: 110px;
}

.about-us .triangle {
  right: 10%;
  width: 40px;
  transform: translateY(-8rem) rotate(54deg);
}

@media (max-width: 1400px) {
  .about-us .grid-container-2 .image-box {
    transform: translateY(2rem);
  }
}

@media (max-width: 1200px) {
  .about-us .grid-container .content p {
    width: 100%;
  }
  .about-us .grid-container-1 .image-box {
    transform: translateY(3rem);
  }
}

@media (max-width: 1000px) {
  .about-us .grid-container {
    grid-template-columns: 1fr;
    justify-items: center;
    text-align: center;
    gap: 2rem;
  }
  .about-us .grid-container .image-box {
    width: 60%;
    transform: none;
  }
  .about-us .grid-container-2 .image-box {
    grid-area: auto;
  }
  .about-us .grid-container-2 .content {
    text-align: center;
  }
}

@media (max-width: 800px) {
  .about-us {
    margin: 2rem 1rem;
  }
  .about-us .grid-container {
    margin-top: 8rem;
  }
  .about-us .grid-container .content {
    padding: 10px;
  }
  .about-us .green-bordered-square {
    width: 30px;
  }
  .about-us .grow-business {
    padding: 5rem 2rem;
  }
  .about-us .grow-business .image-box {
    width: 90%;
  }
  .about-us #copyright {
    margin: 5rem auto;
  }
}

@media (max-width: 600px) {
  .about-us h2 {
    font-size: 2.1rem;
  }
  .about-us p {
    font-size: 1.1rem;
  }
  .about-us .grid-container {
    width: 100%;
  }
  .about-us .grid-container .content h2 {
    margin-bottom: 2rem;
  }
  .about-us .core-values p {
    width: 90%;
  }
  .about-us .grow-business {
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    gap: 4rem;
    padding: 4rem 0;
  }
  .about-us .grow-business .content {
    text-align: center;
    justify-items: center;
  }
  .about-us .grow-business .image-box {
    width: 50%;
  }
}

.contact {
  margin: 2rem 4rem;
  background: #faf9fe;
}

.contact .form-section {
  color: #000;
  background: #faf9fe;
  margin: 0rem 0 0;
  padding: 2rem 0;
}

.contact .form-section .rectangle {
  z-index: 1;
  width: 70px;
}

.contact .form-section .left-rectangle {
  left: 5%;
  transform: none;
}

.contact .form-section .square {
  left: auto;
  right: 10%;
  z-index: 1;
  width: 100px;
  transform: translateY(-2rem);
}

.contact .form-section .green-bordered-square {
  width: 40px;
  margin: auto;
  transform: translate(3rem, -2rem);
}

.contact .form-section .ellipse7 {
  width: 60px;
  transform: none;
}

.contact .form-section .right-rectangle {
  position: static;
}

.contact .form-section h2,
.contact .form-section h1 {
  text-align: center;
  position: relative;
  z-index: 2;
}

.contact .form-section .grid-container {
  grid-template-columns: repeat(2, 1fr);
  margin: 5rem;
  align-items: center;
  gap: 2rem;
}

.contact .form-section .grid-container form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 4rem;
  border-radius: 10px;
  background: #f0effe;
}

.contact .form-section .grid-container form .form-item {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}

.contact .form-section .grid-container form .form-item label {
  letter-spacing: 1px;
  color: #1b39d7;
}

.contact .form-section .grid-container form .form-item > input,
.contact .form-section .grid-container form .form-item > textarea {
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 10px 5px;
  background: #faf9fe;
  color: #1c3cd8;
  transition: all 0.5s;
}

.contact .form-section .grid-container form .form-item > input:focus,
.contact .form-section .grid-container form .form-item > textarea:focus {
  outline: none;
  border: none;
  background: #fff;
}

.contact .form-section .grid-container form button {
  background: #1c3cd8;
  border: none;
  outline: none;
  justify-self: start;
  padding: 10px 1.2rem;
  border-radius: 10px;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.5s;
}

.contact .form-section .grid-container form button:hover {
  background: #133499;
}

.contact .form-section .grid-container .info {
  padding: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.contact .form-section .grid-container .info h2 {
  text-align: left;
  line-height: 1.3;
  margin: 0;
}

.contact .form-section .grid-container .info .call-details p a {
  color: #000;
}

.contact .form-section .grid-container .info .call-details p a svg {
  transform: translateY(0);
}

.contact #copyright {
  color: #1c3cd8;
}

@media (max-width: 1400px) {
  .contact .form-section {
    padding: 4rem 0 0;
  }
  .contact .form-section .grid-container {
    margin: 5rem 3rem;
  }
}

@media (max-width: 1100px) {
  .contact .form-section h1 {
    font-size: 2.5rem;
  }
  .contact .form-section .grid-container {
    margin: 4rem 1rem;
    gap: 1rem;
  }
  .contact .form-section .grid-container .info h2 {
    font-size: 2rem;
  }
}

@media (max-width: 900px) {
  .contact .form-section h1 {
    font-size: 2.2rem;
  }
  .contact .form-section .grid-container {
    grid-template-columns: 1fr;
  }
  .contact .form-section .grid-container .info {
    grid-row: 1/2;
  }
  .contact .form-section .grid-container .info h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 800px) {
  .contact {
    margin: 6rem 1rem 5rem;
  }
  .contact .form-section .square {
    width: 70px;
  }
}

@media (max-width: 600px) {
  .contact .form-section {
    margin: 0;
    padding: 4rem 0;
  }
  .contact .form-section .rectangle {
    width: 40px;
  }
  .contact .form-section .green-bordered-square {
    width: 25px;
  }
  .contact .form-section .grid-container form {
    padding: 2rem;
  }
  .contact .form-section .grid-container .info {
    padding: 1rem;
  }
  .contact .form-section .grid-container .info h2 {
    font-size: 1.6rem;
  }
}

.careers-container {
  margin: 2rem 4rem;
  text-align: center;
  position: relative;
}

.careers-content {
  margin-top: 3rem;
}

.careers-content p {
  font-size: 1.4rem;
}

.careers-content .rectangle {
  width: 60px;
}

.careers-content .left-rectangle {
  right: auto;
  left: 5%;
  transform: translateY(2rem);
}

.careers-content .right-rectangle {
  left: auto;
  right: 0%;
  top: 30%;
}

.careers-content .golden-triangle {
  width: 30px;
  left: 15%;
  top: 85vh;
}

.careers-content .green-bordered-square {
  width: 40px;
  left: 8%;
  transform: translateY(10rem);
  z-index: -1;
}

.careers-content .ellipse8 {
  right: 10%;
  width: 70px;
  transform: translateY(10rem);
}

.careers-content .rotated-ellipse8 {
  right: auto;
  left: 13%;
  bottom: 50vh;
}

.careers-content .header {
  margin-top: 2rem;
  padding: 1rem;
}

.careers-content .header h1 {
  margin-bottom: 1rem;
}

.careers-content .header .tagline {
  width: 80%;
  margin: auto;
}

.careers-content .header .tagline.bold {
  margin-bottom: 8px;
}

.careers-content .header .tagline.medium {
  width: 70%;
}

.careers-content .image-box {
  width: 30%;
  margin: 3rem auto;
}

.careers-content .form-header {
  width: 60%;
  margin: 5rem auto 0;
}

.careers-content form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 4rem;
  border-radius: 10px;
  background: #f0effe;
  width: 50%;
  margin: 2rem auto;
}

.careers-content form .form-item {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  text-align: left;
}

.careers-content form .form-item label {
  letter-spacing: 1px;
  color: #1b39d7;
}

.careers-content form .form-item .sublabel {
  margin-top: 5px;
  font-size: 0.9rem;
}

.careers-content form .form-item > input,
.careers-content form .form-item > textarea {
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 10px 5px;
  background: #faf9fe;
  color: #1c3cd8;
  transition: all 0.5s;
}

.careers-content form .form-item > input:focus,
.careers-content form .form-item > textarea:focus {
  outline: none;
  border: none;
  background: #fff;
}

.careers-content form button {
  background: #1c3cd8;
  border: none;
  outline: none;
  justify-self: start;
  padding: 10px 1.2rem;
  border-radius: 10px;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.5s;
}

.careers-content form button:hover {
  background: #133499;
}

.careers-content #copyright {
  margin-bottom: 5px;
}

.careers-content #copyright {
  color: #1b39d7;
}

@media (max-width: 1200px) {
  .careers-content .image-box {
    width: 50%;
  }
  .careers-content form {
    width: 70%;
  }
}

@media (max-width: 800px) {
  .careers-content {
    margin-top: 10rem;
  }
  .careers-content .image-box {
    width: 60%;
  }
  .careers-content p {
    font-size: 1.2rem;
  }
  .careers-content form {
    width: 90%;
  }
  .careers-content #copyright {
    margin: 5rem auto;
  }
}

@media (max-width: 600px) {
  .careers-container {
    margin: 2rem 1rem;
  }
  .careers-content .header h1 {
    font-size: 2.5rem;
  }
  .careers-content .header .tagline {
    width: 90%;
  }
  .careers-content .header .tagline.medium {
    width: 100%;
  }
  .careers-content .image-box {
    width: 80%;
  }
  .careers-content .rectangle {
    width: 40px;
  }
  .careers-content .left-rectangle {
    left: 2%;
    transform: translateY(-3rem);
  }
  .careers-content .right-rectangle {
    top: 43%;
  }
  .careers-content .golden-triangle {
    left: 0;
  }
  .careers-content .form-header {
    width: 80%;
  }
  .careers-content form {
    width: 100%;
    padding: 2rem;
  }
}

.slider {
  position: relative;
  background: transparent;
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
}

.slider .slider-content {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 0 1rem;
}

.slider .slider-content .myslider {
  display: none;
  overflow: hidden;
  position: relative;
  justify-items: center;
  align-items: center;
}

.slider .slider-content .myslider .image-box {
  padding: 2rem;
  text-align: center;
}

.slider .slider-content .myslider .image-box img {
  width: 60%;
}

.slider .prev,
.slider .next {
  font-size: 1.4rem;
  padding: 5px 8px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  color: #1a1a1a;
  background-color: #ededed;
  outline: none;
  border: none;
  border-radius: 50%;
  transition: all 0.1s;
}

.slider .prev:hover,
.slider .next:hover {
  color: #1c3cd8;
  background-color: #fff;
}

.slider .prev {
  margin-left: 1rem;
}

.slider .next {
  margin-right: 1rem;
}

.slider .fade {
  animation: fade 1.5s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .slider .slider-content .myslider .image-box img {
    width: 90%;
  }
  .slider .prev,
  .slider .next {
    font-size: 1rem;
    height: 40px;
    width: 40px;
  }
}

@media (max-width: 600px) {
  .slider .slider-content {
    padding: 0 10px;
  }
  .slider .slider-content .myslider .image-box {
    padding: 0;
  }
  .slider .slider-content .myslider .image-box img {
    width: 100%;
  }
  .slider .prev,
  .slider .next {
    font-size: 1.1rem;
    padding: 8px 10px;
  }
  .slider .prev {
    margin-left: 10px;
  }
  .slider .next {
    margin-right: 10px;
  }
}

.testimonial-card .slider {
  grid-template-columns: 1fr;
}

.testimonial-card .slider .slider-content .myslider {
  grid-template-columns: repeat(3, 1fr);
  column-gap: 6rem;
}

.testimonial-card .slider .slider-content .myslider .image-box img {
  width: 60%;
}

@media (max-width: 1200px) {
  .testimonial-card .slider .slider-content .myslider .image-box {
    padding: 1rem;
  }
  .testimonial-card .slider .slider-content .myslider .image-box img {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .testimonial-card .slider .slider-content .myslider {
    grid-template-columns: 1fr;
    gap: 4rem;
  }
  .testimonial-card .slider .slider-content .myslider .image-box {
    padding: 1rem;
  }
  .testimonial-card .slider .slider-content .myslider .image-box img {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .testimonial-card .slider .slider-content .myslider {
    gap: 3rem;
  }
}

@media (max-width: 400px) {
  .testimonial-card .slider .slider-content .myslider .image-box img {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .testimonial-card .slider {
    padding: 1rem;
  }
}
